<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="7">
            Employee Demobilization Application 人员撤离动迁审批
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" rowspan="2">
            序号<br />
            SN
          </td>
          <td class="text-center">Project Name<br />项目名称</td>
          <td class="text-center">
            {{ ProjectID | projectFormater(projectList) }}
          </td>
          <td class="text-center">SubProject Name<br />分项目名称</td>
          <td class="text-center">
            <el-select v-model="SubProjectID" placeholder="请选择">
              <el-option
                v-for="item in subProjectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">Working Unit<br />用工单位</td>
          <td class="text-center">
            {{ CompanyID | companyFormater(companyList) }}
          </td>
          <td class="text-center">Working Department<br />部门信息</td>
          <td class="text-center">
            {{ DepartmentID | departmentFormater(departmentList) }}
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">{{ Sponsor | SponsorFormater }}</td>
        </tr>
        <tr>
          <td class="text-center">
            Employee ID <br />
            员工编码
          </td>
          <td class="text-center">Name in Chinese<br />员工姓名</td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            Job Profession<br />
            岗位名称
          </td>
          <td class="text-center">
            Date to First Entry Saudi <br />
            首次入沙时间
          </td>
          <td class="text-center">
            Departure Date from KSA<br />
            沙特起飞日期
          </td>
          <td class="text-center">
            Air Ticket Route<br />
            飞行路线
          </td>
          <td class="text-center underline-text">
            Passport No.<br />
            护照号
          </td>
          <td class="text-center">
            Foreign Affairs Relations<br />
            外事关系
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">{{ item.EmployeeID }}</td>
          <td class="text-center">{{ item.NameinChinese }}</td>
          <td class="text-center">{{ item.NameinEnglish }}</td>
          <td class="text-center">
            {{ item.Nationality | nationalityFormater(nationalityList) }}
          </td>
          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            {{ item.DatetoFirstEntrySaudi | tableDateFrom }}
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.DepartureDatefromKSA"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-select v-model="item.AirTicketRoute" placeholder="请选择" filterable>
              <el-option
                v-for="item in ticketGroupList"
                :key="item.ID"
                :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.PassportCode"
              @input="changePassportCode(index)"
              @blur="changePassportCode(index)"
              placeholder="请输入护照号"
              :disabled="
                CostAllocationDetails[index - 1] &&
                !CostAllocationDetails[index - 1].PassportCode
              "
            />
          </td>
          <td class="text-center">
            <el-select
              v-model="item.ForeignRelationStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            Reason of Demobilization <br />撤离原因
          </td>
          <td class="text-center" colspan="8">
            <el-input v-model="Reason" placeholder="请输入撤离原因" />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="4">Project 项目</td>
          <td class="text-center">
            Working Unit Admin. Dept.<br />用工单位行政部
          </td>
          <td class="text-center" colspan="2">
            {{ WorkingUnitAdminDeptRemark }}
          </td>
          <td class="text-center">Working Unit Manager<br />单位负责人</td>
          <td class="text-center" colspan="5">
            {{ WorkingUnitManagerRemark }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Project Admin. Dept.<br />项目行政部</td>
          <td class="text-center" colspan="2">{{ ProjectAdminDeptRemark }}</td>
          <td class="text-center">
            Project Control/Finance Dept.<br />控制/财务部
          </td>
          <td class="text-center" colspan="2">
            {{ ProjectControlDeptRemark }}
          </td>
          <td class="text-center">Project Procurement Dept.<br />物资供应部</td>
          <td class="text-center" colspan="2">
            {{ ProjectProcurementDeptRemark }}
          </td>
        </tr>
        <tr>
          <td class="text-center">Project Engineering Dept.<br />工程技术部</td>
          <td class="text-center" colspan="2">
            {{ ProjectEngineeringDeptRemark }}
          </td>
          <td class="text-center">Project HSSE Dept.<br />安环部</td>
          <td class="text-center" colspan="2">{{ ProjectHSSEDeptRemark }}</td>
          <td class="text-center">Project QA&QC Dept.<br />质量部</td>
          <td class="text-center" colspan="2">{{ ProjectQADeptRemark }}</td>
        </tr>
        <tr>
          <td class="text-center">
            DC/ Project Design Dept.<br />设计中心/项目设计部
          </td>
          <td class="text-center" colspan="2">{{ ProjectDesignDeptRemark }}</td>
          <td class="text-center">
            Project Manager<br />
            项目经理
          </td>
          <td class="text-center" colspan="5">{{ ProjectManagerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">SNEMEC 中东公司</td>
          <td class="text-center">HR Dept. <br />人力资源部</td>
          <td class="text-center" colspan="2">{{ HRDeptRemark }}</td>
          <td class="text-center">SNEMEC Authorized<br />中东公司领导</td>
          <td class="text-center" colspan="5">{{ SNEMECAuthorizedRemark }}</td>
        </tr>
        <tr>
          <td class="text-center">HR Officer <br />人事专员</td>
          <td class="text-center" colspan="2">{{ HROfficerRemark }}</td>
          <td class="text-center">Foreign Affair Officer<br />外事专员</td>
          <td class="text-center" colspan="2">
            {{ ForeignAffairOfficerRemark }}
          </td>
          <td class="text-center">Ticket Officer<br />票务专员</td>
          <td class="text-center" colspan="2">{{ TicketOfficerRemark }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="3">Note 备注</td>
          <td class="text-center" colspan="3" rowspan="2">
            <el-input
              :rows="2"
              type="textarea"
              placeholder="(HR officer fill out)"
              v-model="HROfficerMemo"
            />
          </td>
          <td class="text-right" colspan="2">
            Change Foreign Relations of Applicant to <br />
            申请人员外事关系变更为
          </td>
          <td class="text-center">
            <el-select
              v-model="ChangeForeignRelationsofApplicantto"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="3" rowspan="2">
            <el-input
              :rows="2"
              type="textarea"
              placeholder="(Ticket officer fill out)"
              v-model="TicketOfficerMemo"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="3">
            <el-input
              :rows="2"
              type="textarea"
              placeholder="(Foreign affair officer fill out)"
              v-model="ForeignAffairOfficerMemo"
            />
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="9">
            *
            持往返签证未按时返沙人员需交还IQAMA原件，否则在最终薪资结算时需扣除1000SAR/人。<br />
            * Those who do not return to Saudi Arabia on time with a re-entry
            exit visa must return the original IQAMA, otherwise 1000 SAR/person
            will be deducted from the final salary settlement.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="12">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="10">
            * 需上传员工辞职信或公司辞退函等材料。<br />
            * The support documents including employee resignation letter or
            company dismissal letter.
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  allPosition,
  getProjectsChildren,
  uploadAttachment,
} from "@/api/user";
import {
  addDemobilizationApplications,
  updateDemobilizationApplication,
  getUsersCode,
  getGeneralBasic,
  getAirlinesAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    SponsorFormater(id) {
      const data = [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
  },
  data() {
    return {
      TableDate: null,
      ListerDept: null,
      ApplicationNumber: null,
      projectList: [],
      companyList: [],
      departmentList: [],
      nationalityList: [],
      positionList: [],
      ticketGroupList: [],
      subProjectList: [],
      ID: null,
      KeyID: null,
      ProjectID: null,
      SubProjectID: null,
      CompanyID: null,
      DepartmentID: null,
      Sponsor: null,
      Reason: null,
      HROfficerMemo: null,
      ForeignAffairOfficerMemo: null,
      TicketOfficerMemo: null,
      ChangeForeignRelationsofApplicantto: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      CostAllocationDetails: [],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        NameinChinese: null,
        NameinEnglish: null,
        Nationality: null,
        JobProfession: null,
        DatetoFirstEntrySaudi: null,
        DepartureDatefromKSA: null,
        AirTicketRoute: null,
        PassportCode: null,
        ForeignRelationStatus: null,
      },
      options: [
        {
          value: 1,
          label: "注销Final Exit/Cancel IQAMA/Sponsor Transfer",
        },
        {
          value: 2,
          label: "保留 Holding Re-entry Visa",
        },
      ],
      WorkingUnitAdminDeptRemark: null,
      WorkingUnitManagerRemark: null,
      ProjectAdminDeptRemark: null,
      ProjectControlDeptRemark: null,
      ProjectProcurementDeptRemark: null,
      ProjectEngineeringDeptRemark: null,
      ProjectHSSEDeptRemark: null,
      ProjectQADeptRemark: null,
      ProjectDesignDeptRemark: null,
      ProjectManagerRemark: null,
      HRDeptRemark: null,
      SNEMECAuthorizedRemark: null,
      HROfficerRemark: null,
      ForeignAffairOfficerRemark: null,
      TicketOfficerRemark: null,
      options2: [
        {
          value: 2,
          label: "保留",
        },
        {
          value: 3,
          label: "调离",
        },
      ],
    };
  },
  methods: {
    getDepartmentList(data1, data2, data3, data4, data5) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.nationalityList = data4;
      this.positionList = data5;
      this.TableDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        DepartmentID: this.DepartmentID,
        Sponsor: this.Sponsor,
        Reason: this.Reason,
        HROfficerMemo: this.HROfficerMemo,
        ForeignAffairOfficerMemo: this.ForeignAffairOfficerMemo,
        TicketOfficerMemo: this.TicketOfficerMemo,
        ChangeForeignRelationsofApplicantto:
          this.ChangeForeignRelationsofApplicantto,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        EditState: EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode && item.EmployeeID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      let isTrue = true;
      data.Details.forEach((item) => {
        if (!item.DepartureDatefromKSA) {
          this.$message.warning("沙特起飞日期不能为空");
          isTrue = false;
        }
        if (!item.AirTicketRoute) {
          this.$message.warning("请求选择飞行路线");
          isTrue = false;
        }
        if (!item.ForeignRelationStatus) {
          this.$message.warning("请求选择外事关系");
          isTrue = false;
        }
      });
      if (!isTrue) {
        return;
      }

      if (!this.ID) {
        addDemobilizationApplications(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      } else {
        updateDemobilizationApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(data1, data2, data3, data4, data5, params) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.nationalityList = data4;
      this.positionList = data5;
      console.log("params", params);

      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.Sponsor = params.Sponsor;
      this.Reason = params.ReasonofDemobilization;
      this.ApplicationNumber = params.ApplicationNumber;
      this.TableDate = params.CreateDate;
      this.HROfficerMemo = params.HROfficerMemo;
      this.ForeignAffairOfficerMemo = params.ForeignAffairOfficerMemo;
      this.TicketOfficerMemo = params.TicketOfficerMemo;
      this.ChangeForeignRelationsofApplicantto =
        params.ChangeForeignRelationsofApplicantto;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.getProjectsChildren();
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          //员工编号
          params.Details[index].EmployeeID = params.Details[index].EmployeeID
            ? params.Details[index].EmployeeID
            : params.Details[index].EmployeeCode;
          //员工英文名
          params.Details[index].NameinEnglish =
            params.Details[index].EmployeeNameEN;
          //员工中文名
          params.Details[index].NameinChinese =
            params.Details[index].EmployeeName;
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.WorkingUnitAdminDeptRemark = params.WorkingUnitAdminDeptRemark;
      this.WorkingUnitManagerRemark = params.WorkingUnitManagerRemark;
      this.ProjectAdminDeptRemark = params.ProjectAdminDeptRemark;
      this.ProjectControlDeptRemark = params.ProjectControlDeptRemark;
      this.ProjectProcurementDeptRemark = params.ProjectProcurementDeptRemark;
      this.ProjectEngineeringDeptRemark = params.ProjectEngineeringDeptRemark;
      this.ProjectHSSEDeptRemark = params.ProjectHSSEDeptRemark;
      this.ProjectQADeptRemark = params.ProjectQADeptRemark;
      this.ProjectDesignDeptRemark = params.ProjectDesignDeptRemark;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.HRDeptRemark = params.HRDeptRemark;
      this.SNEMECAuthorizedRemark = params.SNEMECAuthorizedRemark;
      this.HROfficerRemark = params.HROfficerRemark;
      this.ForeignAffairOfficerRemark = params.ForeignAffairOfficerRemark;
      this.TicketOfficerRemark = params.TicketOfficerRemark;
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.DepartmentID = null;
      this.Sponsor = null;
      this.Reason = null;
      this.HROfficerMemo = null;
      this.ForeignAffairOfficerMemo = null;
      this.TicketOfficerMemo = null;
      this.ChangeForeignRelationsofApplicantto = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.WorkingUnitAdminDeptRemark = null;
      this.WorkingUnitManagerRemark = null;
      this.ProjectAdminDeptRemark = null;
      this.ProjectControlDeptRemark = null;
      this.ProjectProcurementDeptRemark = null;
      this.ProjectEngineeringDeptRemark = null;
      this.ProjectHSSEDeptRemark = null;
      this.ProjectQADeptRemark = null;
      this.ProjectDesignDeptRemark = null;
      this.ProjectManagerRemark = null;
      this.HRDeptRemark = null;
      this.SNEMECAuthorizedRemark = null;
      this.HROfficerRemark = null;
      this.ForeignAffairOfficerRemark = null;
      this.TicketOfficerRemark = null;
    },
    getCostCenters(index, type) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );

      const data = {
        projectId: item.ProjectID,
        companyId: item.CompanyID,
        deptId: item.DepartmentID,
      };
      if (type !== 2) {
        item.SubProjectID = null;
        if (item.ProjectID) {
          this.getProjectsChildren(index, item.ProjectID);
        } else {
          this.SubProjeList = [];
        }
      }
      allCostCenters(data).then((res) => {
        if (res.status === 200) {
          item.CostCenterList = res.response;
          if (res.response.length === 0) {
            this.$message.warning("该选项下不存在成本中心，请重新选择");
          }
          if (res.response.length === 1) {
            item.CostCenterID = res.response[0].Id;
            item.CostCenterCode = res.response[0].Code;
          }
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },
    //生成五项数据
    getCostCenterCode(index) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );
      item.CostCenterList.forEach((cItem) => {
        if (cItem.Id === item.CostCenterID) {
          item.CostCenterCode = cItem.Code;
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },
    //通过录入护照号拿个人信息
    async changePassportCode(index) {
      const value = this.CostAllocationDetails[index].PassportCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        this.CostAllocationDetails[index].EmployeeID = null;
        this.CostAllocationDetails[index].NameinChinese = null;
        this.CostAllocationDetails[index].NameinEnglish = null;
        this.CostAllocationDetails[index].Nationality = null;
        this.CostAllocationDetails[index].JobProfession = null;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi = null;
        return;
      }
      //判断有几条数据了
      let haveNumber = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode) {
          haveNumber++;
        }
      });

      if (haveNumber > 1) {
        console.log("userInfo", userInfo);

        if (userInfo.ProjectID !== this.ProjectID) {
          this.$message.warning("所属项目名称不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (
          userInfo.SubProjectID !== this.SubProjectID &&
          userInfo.SubProjectID !== null
        ) {
          this.$message.warning("所属分项目名称不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.CompanyID !== this.CompanyID) {
          this.$message.warning("所属用工单位不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.CompanyID !== this.CompanyID) {
          this.$message.warning("所属用工单位不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.DepartmentID !== this.DepartmentID) {
          this.$message.warning("所属部门信息不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.SPONSOR !== this.SPONSOR) {
          this.$message.warning("所属SPONSOR不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        this.CostAllocationDetails[index].EmployeeID = userInfo.UserCode;
        this.CostAllocationDetails[index].NameinChinese =
          userInfo.ChineseName;
        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi =
          userInfo.DatetoFirstEntrySaudi;
      } else {
        console.log("userInfo", userInfo);
        this.ProjectID = userInfo.ProjectID;
        this.SubProjectID = userInfo.SubProjectID;
        this.CompanyID = userInfo.CompanyID;
        this.DepartmentID = userInfo.DepartmentID;
        this.Sponsor = userInfo.Sponsor;
        this.CostAllocationDetails[index].EmployeeID = userInfo.UserCode;
        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].NameinChinese =
          userInfo.ChineseName;
        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi =
          userInfo.DatetoFirstEntrySaudi;
        this.getProjectsChildren();
      }
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },

    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        console.log("国籍", this.nationalityList);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 12px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>